@tailwind base;
@tailwind components;
@font-face {
	font-family: 'Bahnschrift';
	src: url('../assets/fonts/bahnschrift.ttf') format('truetype');
}
@tailwind utilities;

.android,
.ios {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.android::-webkit-scrollbar,
.ios::-webkit-scrollbar {
	display: none;
}

#launcher {
	bottom: 80px !important;
	margin: 0px !important;
}

.inputTokenContainer {
	width: 100%;
	display: flex;
	justify-content: space-around;
	justify-items: center;
	align-items: center;
}

.inputTokenCharacter {
	border-radius: 3.31px;
	border-width: 0.83px;
	border-color: #a3a5ab;
	font-size: 24px;
	font-weight: 700;
	text-align: center;
	color: #a3a5ab;
}

.inputTokenActive {
	border-radius: 3.31px;
	border-width: 0.83px;
	border-color: #df1737;
	outline: 0px;
}

.inputTokenInactive {
	border-radius: 3.31px;
	border-width: 0.83px;
	border-color: #a3a5ab;
	background-color: transparent;
}

.inputTokenFilled {
	border-radius: 3.31px;
	border-width: 0.83px;
	border-color: #a3a5ab;
}

.scrollbar-hide {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
	display: none;
}

.select-none {
	user-select: none;
}

.SliderRoot {
	position: relative;
	display: flex;
	align-items: center;
	user-select: none;
	touch-action: none;
	width: 100%;
	height: 20px;
}

.SliderTrack {
	background-color: #eceff3;
	position: relative;
	flex-grow: 1;
	border-radius: 9999px;
	height: 3px;
}

.SliderRange {
	position: absolute;
	background-color: #df1737;
	border-radius: 9999px;
	height: 100%;
}

.SliderThumb {
	display: block;
	width: 16px;
	height: 16px;
	background-color: #df1737;
	box-shadow: 0 2px 10px var(--black-a7);
	border-radius: 10px;
}

.SliderThumb:focus {
	outline: none;
}

.zendesk-iframe {
	padding-top: 45px;
}

@keyframes progress-bar {
	0% {
		transform: scaleX(0);
	}
	100% {
		transform: scaleX(1);
	}
}

.progress-bar {
	animation: progress-bar linear;
}

#webWidget {
	top: 55px !important;
	height: calc(100% - 55px) !important;
}

.indicators {
	margin-top: 0px !important;
}

.custom-scroll::-webkit-scrollbar {
	width: 12px;
}

.custom-scroll::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
	background-color: #949494;
	border-radius: 1.5rem;
	width: 1px;
	border: 3px solid transparent;
	background-clip: padding-box;
}

.str-chat__virtual-list {
	height: 320px;
}

.str-chat__message-list-scroll {
}

.str-chat__empty-channel {
	display: flex;
	flex-direction: column;
	align-content: center;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.str-chat__empty-channel-text {
	font-family: 'Bahnschrift';
	color: #7d7d7d;
}

.str-chat__virtual-list-message-wrapper {
}

em-emoji-picker {
	height: 330px;
}

html,
body {
	height: 100%;
	overflow: auto;
}
